/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "./index.css";
import SvgLogo from "./components/SvgLogo";
import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import {
  HebrewCalendar,
  HDate,
  Location,
  Event,
  Zmanimת,
  months,
} from "@hebcal/core";
import ClockBlock from "./components/ClockBlock";
import ErrorModal from "./components/ErrorModal";
import { Tooltip } from "./components/Tooltip";
const momentTZ = require("moment-timezone");
const timeZonesList = momentTZ.tz.names();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  // turn off console
  useEffect(() => {
    console.log = () => {};
  }, []);

  const [timezone, setTimeZone] = useState(
    timeZonesList.includes(
      new URLSearchParams(window.location.search).get("tzid")
    )
      ? new URLSearchParams(window.location.search).get("tzid")
      : momentTZ.tz.guess()
  );
  const [clock, setClock] = useState(dayjs().tz(timezone));
  const [formatError, setFormatError] = useState("");
  const [params, setParams] = useState({
    longitudeParam: null,
    latitudeParam: null,
    tzidParam: "",
    locationParam: "",
    ilParam: false,
  });
  const { longitudeParam, latitudeParam, tzidParam, locationParam, ilParam } =
    params;
  const [zmanim, setZmanim] = useState({
    date: "",
    gregDate: "",
    event: "",
    time: "",
    desc: "",
    eventLocation: "",
  });

  const t = {
    "Candle lighting": "הדלקת הנירות",
    Havdalah: "הבדלה",
  };
  const [recentRender, setRecentRender] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const [locationParams, setLocationParams] = useState({
    longitude: queryParams.get("longitude"),
    latitude: queryParams.get("latitude"),
    tzid: queryParams.get("tzid"),
    isIl: queryParams.get("il"),
    location: queryParams.get("location"),
  });
  const { longitude, latitude, tzid, isIl, location } = locationParams;
  const beginStart = queryParams.get("date") || dayjs().format("M-DD-YY");

  const setupDateAndHolidays = () => {
    if (latitude < -90 || latitude > 90 || !latitude) {
      setFormatError(
        "invalid/missing latitude coordinates, Please make sure input is valid"
      );
      return;
    } else if (longitude < -180 || longitude > 180 || !longitude) {
      setFormatError(
        "invalid/ missing longitude coordinates, Please make sure input is valid"
      );
      return;
    } else if (!timeZonesList.includes(tzid) || !tzid) {
      setFormatError(
        "invalid/missing timezone ID, Please make sure input is valid"
      );
      return;
    } else if (!isIl) {
      console.log(typeof isIl);
      setFormatError("please fill out the Israel flag");
    }

    const events = HebrewCalendar.calendar({
      noHolidays: true,
      noRoshChodesh: true,
      noMinorFast: true,
      noSpecialShabbat: true,
      hour12: true,
      candlelighting: true,
      location: new Location(latitude, longitude, isIl, tzid),
      sedrot: false,
      omer: false,
      il: isIl,
      start: new HDate(new Date(beginStart)),
      end: new HDate(new Date(beginStart)),
    });

    const zmanimObject = {
      date: dayjs(events[0]?.getDate().greg().toLocaleDateString()).format(
        "D MMMM"
      ),
      jewishDate: ` ${new HDate(new Date()).render("he-x-NoNikud", false)}`,
      events: events
        ?.map((e) => {
          return e.render("he-x-NoNikud");
        })
        .join(", "),
      eventTime: events[0] ? dayjs(events[0]?.eventTime).format("h:mm A") : "",
      eventDesc: events[0]?.desc,
    };
    console.log(zmanimObject);
    setZmanim({
      date: zmanimObject.jewishDate,
      gregDate: zmanimObject.date,
      event: zmanimObject.events,
      time: zmanimObject.eventTime,
      desc: t[zmanimObject.eventDesc] ? t[zmanimObject.eventDesc] : "",
      eventLocation: location,
    });
  };

  useEffect(() => {
    setupDateAndHolidays();
  }, []);

  // sets the clock
  useEffect(() => {
    const interval = setInterval(() => {
      console.log(clock);
      const newTime = dayjs().tz(timezone);
      if (
        !recentRender &&
        parseInt(newTime.format("H")) === 0 &&
        parseInt(newTime.format("m")) === 0
      ) {
        setRecentRender(true);
        setupDateAndHolidays();
        setTimeout(() => setRecentRender(false), 60000);
      }

      setClock(newTime);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    // <div className="app bg-backgroundGrey h-screen w-screen overflow-hidden max-h-screen ">
    <div className="app h-screen w-screen overflow-hidden max-h-screen ">
      {formatError !== "" ? (
        <ErrorModal
          formatError={formatError}
          locationParams={locationParams}
          setLocationParams={setLocationParams}
          timeZonesList={timeZonesList}
        />
      ) : (
        <>
          <div className="flex flex-row-reverse">
            <Tooltip message="DuobleClick to edit">
              <SvgLogo
                className=" hover:cursor-pointer"
                onDoubleClick={() => {
                  setFormatError(true);
                }}
              />
            </Tooltip>
          </div>
          <div className="flex">
            <ClockBlock time={clock.format("h")} />
            <ClockBlock time={clock.format("mm")} />
            <ClockBlock time={clock.format("A")} />
          </div>
          <h1 className=" font-roboto text-gray-200 text-[5vw]">
            {zmanim.gregDate} - {zmanim.date}
          </h1>
          <h4 className="font-roboto text-textYellow text-[4vw]">
            {`${zmanim.time && zmanim.time} ${zmanim.desc && zmanim.desc} ${
              zmanim.eventLocation && zmanim.time ? `- ` : ``
            }`}
            {zmanim.eventLocation}
          </h4>
        </>
      )}
    </div>
  );
}

export default App;
