import React from "react";

const CustomInput = ({
  label,
  required,
  inputValue,
  inputId,
  setLocationParams,
  name,
  locationParams,
}) => {
  return (
    <>
      <label className="text-[2vw]">{label}</label>
      {required && <span className=" text-red-500 ml-[2px]">*</span>}
      <input
        name={name}
        id={inputId}
        defaultValue={inputValue}
        type="text"
        className="block w-full px-2 py-1 text-[1.5vw] flex-1 rounded-md border-textYellow focus:border-textYellow focus:ring-textYellow sm:text-sm"
        onChange={(e) => {
          e.preventDefault();
          setLocationParams({
            ...locationParams,
            [e.target.name]: e.target.value,
          });
        }}
      />
    </>
  );
};

const ErrorModal = ({
  locationParams,
  setLocationParams,
  timeZonesList,
  formatError,
}) => {
  const { longitude, latitude, tzid, isIl, location } = locationParams;
  const onChangeInput = (e) => {
    setLocationParams({
      ...locationParams,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="absolute top-10 left-1/4 w-1/2 h-auto m-auto bg-gray-300 duration-500 ease-in-out transition origin-right p-6 rounded-md">
      <h1 className="text-[2vw] font-bold">Please fill in required fields </h1>
      <h1 className="text-[1.5vw] text-red-600 font-bold">{formatError}</h1>
      <CustomInput
        required={true}
        label="Longitude"
        name="longitude"
        inputId="long"
        inputValue={longitude}
        setLocationParams={setLocationParams}
        locationParams={locationParams}
      />

      <CustomInput
        required={true}
        label="Latitude"
        name="latitude"
        inputId="lat"
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        inputValue={latitude}
        onChange={onChangeInput}
      />
      <label className="text-[2vw]">Timezone</label>
      <span className=" text-red-500 ml-[2px]">*</span>
      <select
        name="tzid"
        id="tzid"
        value={tzid}
        className=" z-100 block w-full px-2 py-1 text-[1.5vw] flex-1 rounded-md border-textYellow focus:border-textYellow focus:ring-textYellow sm:text-sm"
        onChange={(e) => {
          onChangeInput(e);
        }}
      >
        {timeZonesList.map((tz, i) => (
          <option value={tz} key={i}>
            {tz}
          </option>
        ))}
      </select>
      <CustomInput
        label="Location name"
        name="location"
        inputId="location"
        inputValue={location}
        locationParams={locationParams}
        setLocationParams={setLocationParams}
        onChange={onChangeInput}
      />

      <div className="flex flex-row-reverse justify-between items-center">
        <a
          href={`/?latitude=${latitude ? latitude : ""}&longitude=${
            longitude ? longitude : ""
          }&tzid=${tzid ? tzid : ""}&il=${isIl ? isIl : false}&location=${
            location ? location : ""
          }`}
          className=" text-white text-[2.5vw] w-1/2 font-bold px-4 py-1 mt-4 rounded-md text-center bg-textYellow hover:bg-backgroundGrey hover:text-textYellow transition duration-400"
        >
          Continue
        </a>

        <label htmlFor="il" className="text-[2vw] flex-row items-center">
          <div className=" ">Israel?</div>
          <input
            type="checkbox"
            className="bg-textYellow  text-textYellow border-2 border-backgroundGrey accent-textYellow ml-1"
            name="isIl"
            id="isIl"
            checked={JSON.parse(isIl)}
            onChange={(e) => {
              console.log(isIl, typeof isIl);
              setLocationParams({
                ...locationParams,
                isIl: !isIl,
              });
            }}
          />
        </label>
      </div>
    </div>
  );
};

export default ErrorModal;
