import React from "react";

const ClockBlock = ({ time }) => {
  return (
    <div
      //   style={{
      //     aspectRatio: 1 / 1,
      //   }}
      //   className=" grid grid-cols-1 shadow-lg shadow-gray-500 rounded-md m-3 items-center justify-center"
      className="w-1/3 max-h-96 shadow-lg bg-[#33333326] shadow-gray-500 rounded-md m-3 flex items-center justify-center"
    >
      <h1 className="text-textYellow text-[14vw] font-poppins">{time}</h1>
    </div>
  );
};

export default ClockBlock;
